import "./styles/styles.scss";
import { LandingPage } from "./pages/Landing.page";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ROUTES } from "./routes";
import { PhotographyPage } from "./pages/Photography.page";
import { AboutPage } from "./pages/About.page";
import { PortfolioPage } from "./pages/Portfolio.page";
import { ContactPage } from "./pages/Contact.page";
import { ScrollToTop } from "./components/ScrollToTop";

// Init Email Service
import { init } from "emailjs-com";
import {ToastProvider} from "react-toast-notifications";
init("user_s2rDXXNUQPyheQJH9F6Wa");

function App() {
  return (
    <Router>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="top-right"
      >
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path={ROUTES.photography}>
            <PhotographyPage />
          </Route>
          <Route path={ROUTES.about}>
            <AboutPage />
          </Route>
          <Route path={ROUTES.portfolio}>
            <PortfolioPage />
          </Route>
          <Route path={ROUTES.contact}>
            <ContactPage />
          </Route>
          <Route path={ROUTES.home}>
            <LandingPage />
          </Route>
        </Switch>
        <Footer />
      </ToastProvider>
    </Router>
  );
}

export default App;
