import "../styles/about-page.scss"
import aboutHeadshot from "../assets/about-me-sample.jpg"

export const AboutPage = () => (
  <div className="h-center vert-center">
    <div className="about-page-container">
      <div className="image-container about-page-left-image-container">
        <img src={aboutHeadshot} alt="Maggie Francis Headshot" />
      </div>
      <div className="about-page-right-container">
        <h1 style={{ marginBottom: "1rem" }}>About Me</h1>
        <h3 style={{ marginBottom: "3rem" }}>Hey There!</h3>
        <p>
          I've loved to create artwork since I was a little girl. <br />
          A few years ago I bought a used camera with the interest in learning
          about photography. <br />
          From there a passion grew for creating art in many different forms.
          <br />
          Whether it is photography, lettering, painting, or whatever other
          project I can get my hands on, I love to create. Test
          <br />
          <br />
          about
        </p>
        <p>
          I've loved to create artwork since I was a little girl. <br />
          A few years ago I bought a used camera with the interest in learning
          about photography. <br />
          From there a passion grew for creating art in many different forms.
          <br />
          Whether it is photography, lettering, painting, or whatever other
          project I can get my hands on, I love to create. test test
        </p>
        <div className="about-page-two-column">
          <div>
            <h4 style={{ textAlign: "center" }}>A few things about me</h4>
            <ul style={{ textAlign: "center" }}>
              <li>I'm cute</li>
              <li>I'm funny</li>
              <li>I'm smart</li>
              <li>I'm creative as shit</li>
              <li>I have the coolest husband ever</li>
            </ul>
          </div>
          <div>
            <h4 style={{ textAlign: "center" }}>Things I obsess over</h4>
            <ul style={{ textAlign: "center" }}>
              <li>My husband's butt</li>
              <li>Olive, our golden/lab puppy</li>
              <li>Gray's Anatomy</li>
              <li>Brownies</li>
              <li>My two cats Nala and Merida</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);
