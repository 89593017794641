import "../styles/contact-page.scss";
import { useForm } from "react-hook-form";
import jesseDress from "../assets/DSC_4344.jpg";
import { useCallback, useState } from "react";
import emailjs from "emailjs-com";
import { useToasts } from "react-toast-notifications";

//eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const ContactPage = () => {
  const { register, handleSubmit, formState } = useForm();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { addToast } = useToasts();
  const submitForm = useCallback(formData => {
    setIsButtonLoading(true);
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formData
      )
      .then(
        function() {
          addToast("Message Sent Successfully!", {
            appearance: "success",
            autoDismiss: true
          });
          setIsButtonLoading(false);
        },
        function(error) {
          console.log(error);
          addToast("There was an error trying to send your message", {
            appearance: "error",
            autoDismiss: true
          });
          setIsButtonLoading(false);
        }
      );
  }, [addToast]);
  return (
    <div className="grid-wrapper">
      <div className="contact-left-column">
        <div className="image-container image-right vert-center no-overflow">
          <img
            className="contact-image"
            src={jesseDress}
            alt="jesse in a dress"
          />
        </div>
      </div>
      <div className="contact-right-column">
        <h2 style={{ textAlign: "center", marginBottom: "3rem" }}>
          Contact Me
        </h2>
        <form className="contact-form" onSubmit={handleSubmit(submitForm)}>
          <input
            type="text"
            name="name"
            placeholder="name (*)"
            className="text-input contact-form-input"
            {...register("name", {
              required: true,
              message: "Please enter your name"
            })}
          />
          <span className="error-text">
            {formState.errors.name && "Please enter your name"}
          </span>
          <input
            type="text"
            name="email"
            placeholder="email (*)"
            className="text-input contact-form-input"
            {...register("email", { required: true, pattern: EMAIL_REGEX })}
          />
          <span className="error-text">
            {formState.errors.email && "Please enter a valid email address"}
          </span>
          <input
            type="text"
            name="when"
            placeholder="when are we doing this (*)"
            className="text-input contact-form-input"
            {...register("when", { required: true })}
          />
          <span className="error-text">
            {formState.errors.when && "Please provide a rough date"}
          </span>
          <input
            type="text"
            name="location"
            placeholder="where are we going (*)"
            className="text-input contact-form-input"
            {...register("location", { required: true })}
          />
          <span className="error-text">
            {formState.errors.location && "Please provide a rough area"}
          </span>
          <textarea
            name="clientInfo"
            placeholder="Tell me a little about yourself (*)"
            className="textarea-input contact-form-input"
            {...register("clientInfo", { required: true })}
          />
          <span className="error-text">
            {formState.errors.clientInfo && "What's your story? Anything!"}
          </span>
          {/*Non required fields below*/}
          <input
            type="text"
            name="social"
            placeholder="what's your @"
            className="text-input contact-form-input"
            {...register("social")}
          />
          <textarea
            name="additionalInfo"
            placeholder="Anything else?"
            className="textarea-input contact-form-input"
            {...register("additionalInfo")}
          />
          <button
            type="submit"
            className="primary-button"
            disabled={isButtonLoading}
          >
            {isButtonLoading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};
