import { withRouter } from "react-router-dom";

export const RouterLinkButton = withRouter(
  ({
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    children,
    ...rest
  }) => (
    <button
      {...rest} // `children` is just another prop!
      onClick={event => {
        onClick && onClick(event);
        history.push(to);
      }}
    >
      {children}
    </button>
  )
);
